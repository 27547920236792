import { requireAuth, useAuth } from "@/util/auth";

import { NewList } from "@/ui/views/NewList";
import { NextList } from "@/ui/views/NextList";
import { NowList } from "@/ui/views/NowList";
import SectionHeader from "@/ui/views/SectionHeader";

const greetingTime = require("greeting-time");

function TodayPage() {
  const auth = useAuth();

  return (
    <main className="container mb-12 pt-8">
      <NowList userId={auth?.user.uid} />
      <NextList userId={auth?.user.uid} />
    </main>
  );
}

export default requireAuth(TodayPage);
