import { IncomingStudyItems } from "@/ui/views/IncomingStudyItems";
import { ThreadInput } from "@/ui/views/ThreadInput";
import { requireAuth, useAuth } from "@/util/auth";
import { useIncomingStudyByOwner } from "@/util/db";
import { usePrevious } from "@/util/util";
import { useEffect, useState } from "react";

function ThreadsStudyPage() {
  const auth = useAuth();
  const [isFloating, setIsFloating] = useState(true);
  const { data: incoming } = useIncomingStudyByOwner(auth?.user.uid);
  const prevIncomingCount = usePrevious(incoming?.length || 0);

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrolledToBottom =
        Math.ceil(scrollTop + windowHeight) >= documentHeight - 20;

      setIsFloating(!scrolledToBottom);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    setTimeout(() => {
      handleScroll();
    }, 100);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Add effect to scroll to bottom only when new items are added
  useEffect(() => {
    const currentCount = incoming?.length || 0;
    const previousCount = prevIncomingCount || 0;

    if (currentCount > previousCount) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [incoming, prevIncomingCount]);

  return (
    <main className="py-8 container">
      <div className="flex flex-col max-w-lg mx-auto thread-container">
        <IncomingStudyItems incoming={incoming || []} />
        <div className="sticky bottom-12 z-10  mt-12">
          <ThreadInput
            userId={auth?.user.uid}
            isFloating={isFloating}
            isStudyMode={true}
          />
        </div>
      </div>
    </main>
  );
}

export default requireAuth(ThreadsStudyPage);
