import { cn } from "@/lib/utils";
import { Button } from "@/ui/components/button";
import type { buttonVariants } from "@/ui/components/button";
import type { VariantProps } from "class-variance-authority";
import * as React from "react";

const Toolbar = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center gap-1 mb-4", className)}
    {...props}
  />
));
Toolbar.displayName = "Toolbar";

const ToolbarButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof buttonVariants>
>(({ className, variant, size, ...props }, ref) => (
  <Button
    ref={ref}
    variant={variant}
    size={size}
    className={cn("", className)}
    {...props}
  />
));
ToolbarButton.displayName = "ToolbarButton";

export { Toolbar, ToolbarButton };
