import LoadingIcon from "@/ui/components/LoadingIcon";
import { Button } from "@/ui/components/button";
import type React from "react";
import { useEffect, useState } from "react";
import Section from "./Section";

type PageLoaderProps = {
  children?: React.ReactNode;
};

function PageLoader(props: PageLoaderProps) {
  const [showSignIn, setShowSignIn] = useState(false);

  useEffect(() => {
    // Only start the timeout if there are no children
    if (!props.children) {
      const timer = setTimeout(() => {
        setShowSignIn(true);
      }, 3000); // 3 seconds

      // Cleanup timeout when component unmounts
      return () => clearTimeout(timer);
    }
  }, [props.children]);

  return (
    <Section size="lg">
      {!props.children && !showSignIn && (
        <LoadingIcon className="mx-auto w-7" />
      )}
      {!props.children && showSignIn && (
        <div className="text-center text-xl p-4">
          <Button
            onClick={() => {
              window.webkit?.messageHandlers?.authRequest?.postMessage(
                "signin",
              );
            }}
          >
            Sign in
          </Button>
        </div>
      )}
      {props.children && <>{props.children}</>}
    </Section>
  );
}

export default PageLoader;
