import { IncomingItems } from "@/ui/views/IncomingItems";
import { ThreadInput } from "@/ui/views/ThreadInput";
import { ThreadList } from "@/ui/views/ThreadList";
import { requireAuth, useAuth } from "@/util/auth";
import { useIncomingByOwner } from "@/util/db";
import { useEffect, useState } from "react";

function ThreadsFullPage() {
  const auth = useAuth();
  const [isFloating, setIsFloating] = useState(true);
  const { data: incoming } = useIncomingByOwner(auth?.user.uid);

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrolledToBottom =
        Math.ceil(scrollTop + windowHeight) >= documentHeight - 20;

      setIsFloating(!scrolledToBottom);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    setTimeout(() => {
      handleScroll();
    }, 100);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Add effect to scroll to bottom when incoming changes
  useEffect(() => {
    if (incoming && incoming.length > 0) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [incoming]);

  return (
    <main className="py-8 container">
      <div className="flex flex-col max-w-lg mx-auto thread-container">
        <ThreadList userId={auth?.user.uid} />
        <IncomingItems incoming={incoming || []} />
        <div className="sticky bottom-12 z-10  mt-12">
          <ThreadInput
            userId={auth?.user.uid}
            isFloating={isFloating}
            isStudyMode={false}
          />
        </div>
      </div>
    </main>
  );
}

export default requireAuth(ThreadsFullPage);
