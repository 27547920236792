import { Button } from "@/ui/components/button";
import { Card, CardContent } from "@/ui/components/card";
import { deleteIncoming } from "@/util/db";
import { Trash2 } from "lucide-react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

interface IncomingItem {
  id: string;
  command_text: string;
}

interface IncomingItemsProps {
  incoming: IncomingItem[];
}

export function IncomingItems({ incoming }: IncomingItemsProps) {
  if (!incoming || incoming.length === 0) return null;

  return (
    <Card className="opacity-50">
      <CardContent>
        <TransitionGroup>
          {incoming.reverse().map((item) => (
            <CSSTransition key={item.id} timeout={300} classNames="thread">
              <div className="rounded p-4 flex items-center gap-6">
                <div className="flex flex-col gap-2">
                  <div>{item.command_text}</div>
                  <div className="text-sm text-gray-500">Processing…</div>
                </div>
                <Button
                  className="ml-auto p-2"
                  variant="ghost"
                  onClick={() => deleteIncoming(item.id)}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </CardContent>
    </Card>
  );
}
