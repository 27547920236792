import { ListsList } from "@/ui/views/ListsList";
import SectionHeader from "@/ui/views/SectionHeader";
import { requireAuth, useAuth } from "@/util/auth";
function ListsPage() {
  const auth = useAuth();

  return (
    <main className="py-8 px-8 pt-16">
      {/* <SectionHeader title="Lists" /> */}
      <ListsList userId={auth?.user.uid} />
    </main>
  );
}

export default requireAuth(ListsPage);
