import { ThreadCard } from "@/ui/components/ThreadCard";
import { CardContainer, CardPrefix } from "@/ui/components/card";
import { friendlyTimeSince } from "@/util/date";
import {
  type Thread,
  hackRefreshIncoming,
  updateThread,
  useThreadsAndEventsByOwner,
} from "@/util/db";
import { useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./ThreadList.css";

interface ThreadListProps {
  userId: string;
}

export function ThreadList({ userId }: ThreadListProps) {
  const { data: threads } = useThreadsAndEventsByOwner(userId) as {
    data: Thread[] | null;
  };

  useEffect(() => {
    hackRefreshIncoming();
  }, [threads]);

  const handleTitleChange = async (threadId: string, newTitle: string) => {
    await updateThread(threadId, { title: newTitle });
  };

  const renderThreads = () => {
    if (!threads) return null;

    let lastTimestamp: Date | null = null;
    return threads.map((thread) => {
      const currentTimestamp = new Date(thread.created_at);
      let timeText = null;

      if (
        !lastTimestamp ||
        currentTimestamp.getTime() - lastTimestamp.getTime() > 1800000
      ) {
        timeText = friendlyTimeSince(currentTimestamp);
      }
      lastTimestamp = currentTimestamp;

      const isCompleted = thread.thread_events?.some((event: any) => {
        return (
          event.text?.toLowerCase().includes("confirmed") ||
          event.text?.toLowerCase().includes("cancelled")
        );
      });

      return (
        <CSSTransition key={thread.id} timeout={300} classNames="thread">
          <CardContainer>
            <CardPrefix className="text-sm font-mono-krypton text-muted-foreground align-right w-full justify-center">
              {timeText}
            </CardPrefix>
            <ThreadCard
              id={thread.id}
              title={thread.title}
              events={thread.thread_events}
              isCompleted={isCompleted}
              onTitleChange={handleTitleChange}
            />
          </CardContainer>
        </CSSTransition>
      );
    });
  };

  return (
    <div>
      <TransitionGroup>{renderThreads()}</TransitionGroup>
    </div>
  );
}
