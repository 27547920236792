import { ThreadMenu } from "@/ui/components/ThreadMenu";
import { Card, CardContent } from "@/ui/components/card";
import { ActionPrompt } from "@/ui/views/ActionPrompt";
import type { ThreadEvent } from "@/util/db";
import type { IncomingStudyItemMetadata } from "@/util/db";
import { animated, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { useEffect, useRef, useState } from "react";

// Add touch detection function
const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};

interface ThreadCardProps {
  id: string;
  title: string;
  events?: ThreadEvent[];
  isCompleted?: boolean;
  isArchived?: boolean;
  audioUrl?: string;
  onArchive?: () => void;
  onDelete?: () => void;
  onTitleChange?: (id: string, newTitle: string) => Promise<void>;
  enableSwipe?: boolean;
  metadata?: IncomingStudyItemMetadata;
}

// Add projection calculation function
const calculateProjectedDistance = (
  velocity: number,
  decelerationRate = 0.998,
) => {
  return ((velocity / 1000.0) * decelerationRate) / (1.0 - decelerationRate);
};

export function ThreadCard({
  id,
  title,
  events = [],
  isCompleted,
  isArchived,
  audioUrl,
  onArchive,
  onDelete,
  onTitleChange,
  enableSwipe = false,
  metadata,
}: ThreadCardProps) {
  const [{ x, y, rotate, opacity }, api] = useSpring(() => ({
    x: 0,
    y: 0,
    rotate: 0,
    opacity: 1,
  }));
  const [isDragging, setIsDragging] = useState(false);
  const expandedElementRef = useRef<HTMLAudioElement>(null);
  const [willArchive, setWillArchive] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const hasDetails = metadata || audioUrl;
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const titleRef = useRef<HTMLTextAreaElement>(null);

  const bind = useDrag(
    ({ movement: [mx, my], down, velocity: [vx, vy], cancel }) => {
      // Add touch device check
      if (!enableSwipe || !isTouchDevice()) return;

      setIsDragging(down);

      // Adjust velocity based on movement direction. Weirdly, the velocity is unsigned?
      // This is still incorrect since you could fling back towards center :/

      const adjustedVx = Math.sign(mx) * Math.abs(vx);
      const adjustedVy = Math.sign(my) * Math.abs(vy);

      const projectedX = mx + calculateProjectedDistance(adjustedVx * 500);
      const projectedY = my + calculateProjectedDistance(adjustedVy * 500);
      const trigger = !down && Math.abs(projectedX) > 200;
      setWillArchive(trigger);

      const rotation = mx / 100;

      if (trigger && onArchive) {
        console.log("triggering archive", projectedX, vx, mx);
        api.start({
          x: projectedX,
          y: projectedY,
          rotate: rotation,
          opacity: 0, // Fade out completely when archived
          immediate: false,
          config: { tension: 200, friction: 20 },
          onRest: () => {
            onArchive();
          },
        });
        return;
      }

      api.start({
        x: down ? mx : 0,
        y: down ? my / 4 : 0,
        rotate: down ? rotation : 0,
        opacity: 1,
        immediate: down,
      });
    },
    {
      bounds: {
        top: -50,
        bottom: 50,
      },
      filterTaps: false,
      preventScroll: true,
      preventDefault: true,
    },
  );

  const handleTitleClick = () => {
    if (onTitleChange) {
      setEditedTitle(title);
      setIsEditing(true);
    }
  };

  // Add effect to select all text when editing starts
  useEffect(() => {
    if (isEditing && titleRef.current) {
      const textarea = titleRef.current;
      textarea.select();
      setTimeout(() => {
        textarea.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    }
  }, [isEditing]);

  const adjustTextareaHeight = (element: HTMLTextAreaElement) => {
    element.style.height = "0px";
    element.style.height = `${element.scrollHeight}px`;
    const lineHeight = Number.parseInt(
      window.getComputedStyle(element).lineHeight,
    );
    const minHeight = Math.max(lineHeight || 24, element.scrollHeight); // Ensure minimum height is at least one line
    element.style.height = `${minHeight}px`;
  };

  const handleTitleUpdate = async () => {
    if (editedTitle === "" && onDelete) {
      onDelete();
    } else if (editedTitle !== title && onTitleChange) {
      await onTitleChange(id, editedTitle);
    }
  };

  const handleTitleBlur = async () => {
    setIsEditing(false);
    await handleTitleUpdate();
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.altKey && !e.shiftKey) {
      e.preventDefault();
      handleTitleUpdate();
      setIsEditing(false);
    }
    if (e.key === "Escape") {
      setEditedTitle(title);
      setIsEditing(false);
    }
  };

  const cardContent = (
    <Card key={id} className={`${willArchive || isArchived ? "bg-muted" : ""}`}>
      <ThreadMenu
        threadId={id}
        onArchive={onArchive}
        onDelete={onDelete}
        isArchived={isArchived}
        showDetails={showDetails}
        onShowDetails={
          hasDetails ? () => setShowDetails(!showDetails) : undefined
        }
      />

      <div className="flex flex-row justify-between items-top">
        <CardContent
          className="rounded p-4 flex items-top align-top flex flex-col gap-2 text-muted-foreground select-text w-full"
          key={id}
        >
          {isEditing ? (
            <textarea
              ref={titleRef as any}
              value={editedTitle}
              onChange={(e) => {
                setEditedTitle(e.target.value);
                adjustTextareaHeight(e.target);
              }}
              onBlur={handleTitleBlur}
              onKeyDown={handleTitleKeyDown}
              className="font-bold w-full mr-8 bg-transparent resize-none overflow-hidden leading-normal"
              style={{ width: "calc(100% - 2rem)" }}
              rows={1}
              onFocus={(e) => adjustTextareaHeight(e.target)}
            />
          ) : (
            <div
              className="font-bold mr-8 select-text cursor-pointer whitespace-pre-wrap"
              onClick={handleTitleClick}
              onKeyDown={(e) => e.key === "Enter" && handleTitleClick()}
            >
              {editedTitle}
            </div>
          )}

          {hasDetails && showDetails && (
            <div className="space-y-4 mt-2">
              {audioUrl && (
                <audio
                  controls
                  ref={expandedElementRef}
                  className="w-full mt-2"
                >
                  <source src={audioUrl} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              )}

              {metadata?.categories && (
                <div className="flex flex-row gap-2 flex-wrap">
                  {metadata.categories.map((category) => (
                    <span
                      key={category}
                      className="text-xs capitalize text-active-card bg-card-foreground rounded-md px-2 py-1 font-mono"
                    >
                      {category.toLowerCase()}
                    </span>
                  ))}
                </div>
              )}
            </div>
          )}

          {events.map((event) => {
            if (
              event.type === "USER_PROMPT" &&
              event.data.action_preview &&
              !event.data.handled
            ) {
              return <ActionPrompt key={event.id} event={event} />;
            }

            if (event.type === "COMMAND") {
              if (event.text.toLowerCase() !== title.toLowerCase()) {
                return (
                  <div
                    key={event.id}
                    className="text-sm opacity-50 text-muted-foreground font-mono-radon"
                  >
                    {event.text}
                  </div>
                );
              }
            } else if (event.type === "USER_PROMPT") {
              if (event.text.toLowerCase().includes("cancelled")) {
                return null;
              }
              return (
                <div
                  key={event.id}
                  className="text-sm text-muted-foreground font-mono-krypton text-purple-500"
                >
                  {event.text}
                </div>
              );
            } else if (!isCompleted) {
              return (
                <div
                  key={event.id}
                  className="text-sm text-muted-foreground font-mono-radon"
                >
                  {event.text}
                </div>
              );
            }
            return null;
          })}
        </CardContent>
      </div>
    </Card>
  );

  if (!enableSwipe || !isTouchDevice()) {
    return cardContent;
  }

  return (
    <animated.div
      {...bind()}
      style={{
        x,
        y,
        rotate,
        opacity, // Add opacity to animated styles
        touchAction: "none",
        zIndex: isDragging ? 2 : "auto",
      }}
    >
      {cardContent}
    </animated.div>
  );
}
