import { ThemeProvider } from "@/ui/views/theme-provider";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./ui/App";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);
