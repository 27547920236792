import { useAuth } from "@/util/auth";
import { Link, useLocation, useNavigate } from "@/util/router";
import Section from "./Section";

import { LogOut, Settings } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/ui/components/dropdown-menu";

import { ReactComponent as LogoSvg } from "@/resources/images/logo.svg";

import { AddItemDialog } from "@/ui/views/AddItemDialog";
import { createIncoming } from "@/util/db";

function Navbar() {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const showFullUI = auth?.user?.setting_full_ui;

  const bestName = () => {
    if (auth?.user?.first_name) {
      return auth.user.first_name;
    }
    return auth?.user?.email?.split("@")[0];
  };

  const isActive = (path: string) => {
    return location.pathname === path
      ? "text-vermillion-500 font-medium"
      : "text-gray-300";
  };
  return (
    // We use z-[60] to make sure the navbar is above the dropdown menu which is z-50
    <Section bgColor="bg-black/80 sticky top-0  backdrop-blur-sm z-[60]">
      <div className="container py-4">
        <div className="flex justify-between">
          <Link
            to="/"
            className="inline-flex items-center space-x-2 font-bold text-lg tracking-wide text-white hover:text-gray-300"
          >
            <LogoSvg className="h-8 w-8" />
            <span className="hidden lg:block">Minder</span>
          </Link>
          <div className="flex space-x-1 flex-grow">
            {(!auth || !auth.user) && (
              <ul className="flex items-center w-full justify-end space-x-4">
                <li className="relative group">
                  <Link to="/auth/signin" className="text-gray-300">
                    <span>Sign in</span>
                  </Link>
                </li>
              </ul>
            )}

            {auth?.user && (
              <ul className="flex items-center w-full justify-end space-x-4">
                <li className="relative group">
                  <AddItemDialog
                    className=""
                    userId={auth?.user?.uid}
                    onItemAdded={async (text) => {
                      await createIncoming({
                        owner: auth?.user.uid,
                        command_text: text,
                        context: {
                          datetime_user: new Date().toISOString(),
                        },
                      });
                    }}
                  />
                </li>

                {showFullUI && (
                  <li className="relative group">
                    <Link to="/now" className={isActive("/now")}>
                      Now
                    </Link>
                  </li>
                )}
                {showFullUI && (
                  <li className="relative group">
                    <Link to="/threads" className={isActive("/threads")}>
                      Threads
                    </Link>
                  </li>
                )}
                {!showFullUI && (
                  <li className="relative group">
                    <Link to="/minder" className={isActive("/minder")}>
                      Minder
                    </Link>
                  </li>
                )}
                {showFullUI && (
                  <li className="relative group">
                    <Link to="/lists" className={isActive("/lists")}>
                      Lists
                    </Link>
                  </li>
                )}
                <li className="relative group">
                  <DropdownMenu>
                    <DropdownMenuTrigger className="text-gray-300">
                      {bestName()}&nbsp;⏷
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      className="w-56"
                      align="end"
                      forceMount
                    >
                      <DropdownMenuItem className="text-gray-300 pointer-events-none">
                        {auth.user.email}
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        onClick={() => {
                          navigate("/settings");
                        }}
                      >
                        <Settings className="mr-2 h-4 w-4" />
                        Settings
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          auth.signout();
                        }}
                      >
                        <LogOut className="mr-2 h-4 w-4" />
                        Sign out
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Navbar;
