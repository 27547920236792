import { formatDistanceToNow } from "date-fns";

export function friendlyTimeSince(timestamp: Date): string {
  const now = new Date();
  const diffInHours = (now.getTime() - timestamp.getTime()) / (1000 * 60 * 60);

  if (diffInHours <= 2) {
    return formatDistanceToNow(timestamp, { addSuffix: true });
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const threadDate = new Date(timestamp);
  threadDate.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  if (threadDate.getTime() === yesterday.getTime()) {
    return `Yesterday ${new Intl.DateTimeFormat("en-US", options).format(timestamp)}`;
  }

  if (threadDate.getTime() < today.getTime()) {
    options.weekday = "long";
  }

  return new Intl.DateTimeFormat("en-US", options).format(timestamp);
}
