import { Button } from "@/ui/components/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/ui/components/dropdown-menu";
import { deleteThread } from "@/util/db";
import { Check, Info, MoreVertical, Trash2 } from "lucide-react";

interface ThreadMenuProps {
  threadId: string;
  onArchive?: () => void;
  onDelete?: () => void;
  audioUrl?: string;
  onPlayAudio?: () => void;
  isArchived?: boolean;
  onShowDetails?: () => void;
  showDetails?: boolean;
}

export function ThreadMenu({
  threadId,
  onArchive,
  onDelete,
  isArchived,
  onShowDetails,
  showDetails,
}: ThreadMenuProps) {
  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    } else {
      deleteThread(threadId);
    }
  };

  const handlePointerDown = (e: React.PointerEvent) => {
    e.stopPropagation();
  };

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          size="circle"
          variant="ghost"
          className="m-2 absolute top-0 right-0"
          onPointerDown={handlePointerDown}
        >
          <MoreVertical className="h-4 w-4 text-muted-foreground" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {onShowDetails && (
          <DropdownMenuItem onClick={onShowDetails}>
            <Info className="h-4 w-4 mr-2" />
            {showDetails ? "Hide" : "Show"} details
          </DropdownMenuItem>
        )}

        {onArchive && (
          <DropdownMenuItem onClick={onArchive}>
            <Check className="h-4 w-4 mr-2" />
            {isArchived ? "Unarchive" : "Archive"}
          </DropdownMenuItem>
        )}

        <DropdownMenuItem onClick={handleDelete}>
          <Trash2 className="h-4 w-4 mr-2" />
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
