import * as React from "react";

import { cn } from "@/lib/utils";

const CardContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-0 mb-2", className)}
    {...props}
  />
));
CardContainer.displayName = "CardContainer";

const CardCaption = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
CardCaption.displayName = "CardCaption";

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "relative rounded-xl shadow-[0px_6px_10px_4px_rgba(0,0,0,0.075),0px_2px_3px_0px_rgba(0,0,0,0.15)] bg-card text-card-foreground overflow-auto",
      className,
    )}
    {...props}
  />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1 py-4 px-4 w-full", className)}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardRow = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex flex-row justify-between items-center space-y-0 px-0 py-0 border-b last:border-b-0",
      className,
    )}
    {...props}
  />
));
CardRow.displayName = "CardRow";

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h3
    ref={ref}
    className={cn(
      "text-lg font-medium text-secondary-foreground leading-5 ",
      className,
    )}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardContainerTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h2
    ref={ref}
    className={cn(
      "text-lg font-medium text-secondary-foreground uppercase leading-none tracking-widest ",
      className,
    )}
    {...props}
  />
));
CardContainerTitle.displayName = "CardContainerTitle";

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("", className)} {...props} />
));
CardContent.displayName = "CardContent";

const CardPreview = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-4", className)} {...props} />
));
CardPreview.displayName = "CardPreview";

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-6 pt-0", className)}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

const CardPrefix = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex items-center py-3 text-secondary-foreground text-sm",
      className,
    )}
    {...props}
  />
));
CardPrefix.displayName = "CardPrefix";

const CardSuffix = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-6 pt-0", className)}
    {...props}
  />
));
CardSuffix.displayName = "CardSuffix";

export {
  CardContainer,
  CardContainerTitle,
  Card,
  CardPrefix,
  CardHeader,
  CardRow,
  CardFooter,
  CardSuffix,
  CardTitle,
  CardDescription,
  CardContent,
  CardPreview,
};
