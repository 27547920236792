import { Button } from "@/ui/components/button";
import { Card, CardContent } from "@/ui/components/card";
import { createIncoming, createIncomingStudy } from "@/util/db";
import { Camera, CornerDownLeft, Keyboard, Mic } from "lucide-react";
import { useRef, useState } from "react";

interface ThreadInputProps {
  userId: string;
  isFloating?: boolean;
  isStudyMode?: boolean;
}

export function ThreadInput({
  userId,
  isFloating,
  isStudyMode,
}: ThreadInputProps) {
  const [inputText, setInputText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async () => {
    if (inputText.trim()) {
      inputRef.current?.blur();
      if (isStudyMode) {
        await createIncomingStudy({
          user_id: userId,
          text: inputText.trim(),
          original_text: inputText.trim(),
        });
      } else {
        await createIncoming({
          owner: userId,
          command_text: inputText.trim(),
        });
      }
      setInputText("");
      setTimeout(() => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    } else {
      inputRef.current?.focus();
    }
  };

  const hasText = inputText.length > 0;
  const isFloatingStyle = isFloating || isFocused || hasText;

  return (
    <div>
      <Card
        className={`mt-4 -mb-4 border transition-all duration-200 focus-within:ring-2 focus-within:ring-blue-500 ${
          isFloatingStyle
            ? "bg-active-card -mx-1 dark:bg-card shadow-[0px_6px_10px_4px_rgba(0,0,0,0.075),0px_2px_3px_0px_rgba(0,0,0,0.15)] border-card dark:border-card"
            : ""
        }`}
      >
        <CardContent className="p-2">
          <div className="flex items-center gap-2">
            <div className="flex-1 relative">
              <input
                ref={inputRef}
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                placeholder=""
                className="w-full p-2 focus:outline-none bg-transparent"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
            </div>

            {false && (
              <Button
                variant={hasText ? "default" : "ghost"}
                size="circle"
                onClick={handleSubmit}
                className={hasText ? "bg-vermillion-500" : ""}
              >
                {inputText.length > 0 ? (
                  <CornerDownLeft className="h-5 w-5" />
                ) : (
                  <Keyboard className="h-7 w-7 text-muted-foreground" />
                )}
              </Button>
            )}

            <Button
              variant="ghost"
              size="icon"
              className="hover:bg-gray-100"
              onClick={() => {
                // TODO: Add add web based microphone input
                console.log("Microphone clicked");
                window.webkit?.messageHandlers?.event?.postMessage({
                  type: "capture",
                });
              }}
            >
              <Mic className="h-6 w-6 text-muted-foreground" />
            </Button>
            {false && (
              <Button
                variant="ghost"
                size="icon"
                className="hover:bg-gray-100"
                onClick={() => {
                  console.log("Camera clicked");
                  window.webkit?.messageHandlers?.haptic?.postMessage({
                    type: "notification",
                    style: "error",
                  });
                }}
              >
                <Camera className="h-7 w-7 text-muted-foreground" />
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
