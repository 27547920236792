import { ThreadMenu } from "@/ui/components/ThreadMenu";
import { CardRow } from "@/ui/components/card";
import type { Thread } from "@/util/db";

interface ThreadRowProps {
  thread: Thread;
}

export function ThreadRow({ thread }: ThreadRowProps) {
  if (!thread.title) return null;
  return (
    <CardRow className="p-4">
      {thread.title}
      <ThreadMenu threadId={thread.id} />
    </CardRow>
  );
}

export default ThreadRow;
