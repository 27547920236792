import { Button } from "@/ui/components/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/ui/components/card";
import { Input } from "@/ui/components/input";
import { Switch } from "@/ui/components/switch";
import SectionHeader from "@/ui/views/SectionHeader";
import { requireAuth, useAuth } from "@/util/auth";
import { updateUser } from "@/util/db";
import { apiRequest } from "@/util/util";
import { useEffect, useState } from "react";

function SettingsPage() {
  const auth = useAuth();

  const [apiProfile, setApiProfile] = useState<any | null>(null);

  const [showDebug, setShowDebug] = useState(false);

  function refreshProfile() {
    apiRequest("profile", "GET").then((result) => {
      console.log("profile", result);
      setApiProfile(result);
    });
  }

  function googleLogout() {
    apiRequest("googlelogout", "GET").then((result) => {
      refreshProfile();
    });
  }

  function subscribeEmails() {
    apiRequest("subscribe-gmail", "POST").then((result) => {
      console.log("subscribe-gmail", result);
    });
  }

  useEffect(() => {
    refreshProfile();
  }, []);

  return (
    <div className="max-w-lg m-auto grid grid-cols-2 gap-4 p-8 content-start items-center justify-center">
      <SectionHeader title="Settings" />
      <Card className="col-span-2">
        <CardHeader>
          <div className="flex items-top justify-between gap-x-4">
            <CardTitle>Email forwarding</CardTitle>
            <Switch
              checked={auth?.user.setting_email_reminders}
              onCheckedChange={() =>
                updateUser(auth?.user.id, {
                  setting_email_reminders: !auth?.user.setting_email_reminders,
                })
              }
            />
          </div>
        </CardHeader>
        <CardContent className="space-y-2">
          <div className="px-4 pb-4 flex items-top aligngap-2 gap-x-4">
            <div>
              Send emails to{" "}
              <span className="text-muted-foreground">{auth?.user.email}</span>{" "}
              for each new Minder item.
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className="col-span-2">
        <CardHeader>
          <CardTitle>Google Account</CardTitle>
        </CardHeader>
        <CardContent>
          {!apiProfile && (
            <div className="p-4">Can't load credentials right now.</div>
          )}
          {apiProfile && !apiProfile.hasGoogleCreds && (
            <div className="p-4 w-full md:w-1/2">
              <div className="p-6 rounded border border-shadow text-peach prose prose-a:text-peach max-w-none">
                <h3 className="mb-4 text-palepeach">
                  Link your Gmail, Calendar and Drive
                </h3>
                <Button
                  onClick={() => {
                    window.location.href = "/googlelogin";
                  }}
                >
                  <img
                    src={"https://uploads.divjoy.com/icon-google.svg"}
                    alt={"Google"}
                    className="w-5 h-5"
                  />
                </Button>
              </div>
            </div>
          )}
          {apiProfile?.hasGoogleCreds && (
            <div className="p-4 w-full md:w-1/2">
              <div className="p-6 rounded border border-shadow text-peach prose prose-a:text-peach max-w-none">
                <h3 className="mb-4 text-palepeach">
                  Unlink Gmail, Calendar and Drive
                </h3>
                <Button onClick={() => googleLogout()}>Unlink</Button>
              </div>
            </div>
          )}
          {apiProfile?.hasGoogleCreds && (
            <div className="p-4 w-full md:w-1/2">
              <div className="p-6 rounded border border-shadow text-peach prose prose-a:text-peach max-w-none">
                <h3 className="mb-4 text-palepeach">
                  Subscribe to all incoming emails
                </h3>
                <Button onClick={() => subscribeEmails()}>Subscribe</Button>
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      <Card className="col-span-2 mb-12 text-muted-foreground bg-muted">
        <CardHeader className="flex flex-row items-center justify-between">
          <div>
            <CardTitle>Debug</CardTitle>
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setShowDebug(!showDebug)}
          >
            {showDebug ? "Hide" : "Show"} Debug Info
          </Button>
        </CardHeader>
        {showDebug && (
          <CardContent
            className="p-4 text-xs"
            style={{
              maxWidth: "90vw",
              whiteSpace: "pre-wrap",
              overflowX: "scroll",
            }}
          >
            <p>user</p>
            <code>{JSON.stringify(auth?.user)}</code>
            <p>apiProfile</p>
            <code>{JSON.stringify(apiProfile)}</code>
          </CardContent>
        )}
      </Card>
    </div>
  );
}

export default requireAuth(SettingsPage);
