import AuthPage from "@/ui/pages/AuthPage";
import ListsPage from "@/ui/pages/ListsPage";
import PrivacyPage from "@/ui/pages/PrivacyPage";
import QuietPage from "@/ui/pages/QuietPage";
import SettingsPage from "@/ui/pages/SettingsPage";
import ThreadsPage from "@/ui/pages/ThreadsPage";
import ThreadsStudyPage from "@/ui/pages/ThreadsStudyPage";
import TodayPage from "@/ui/pages/TodayPage";
import WelcomePage from "@/ui/pages/WelcomePage";
import Navbar from "@/ui/views/Navbar";
import { ThemeProvider } from "@/ui/views/theme-provider";
import { AuthProvider, IOSAuthInjectionProvider } from "@/util/auth";
import { QueryClientProvider } from "@/util/db";
import { Route, Router, Routes } from "@/util/router";

function App() {
  const userAgent = navigator.userAgent;
  const isNativeApp = userAgent.includes("Minder");

  return (
    <QueryClientProvider>
      <AuthProvider>
        <IOSAuthInjectionProvider>
          <ThemeProvider>
            <Router>
              <Routes>
                {/* Suppress the navbar for these routes. */}
                <Route path="/" element={<QuietPage />} />
                {/* The meet urls were previously distributed publicly, so serve something quiet. */}
                <Route path="/meet" element={<QuietPage />} />
                <Route path="/meet/:person" element={<QuietPage />} />

                <Route
                  path="*"
                  element={
                    <>
                      {!isNativeApp && <Navbar />}
                      <Routes>
                        <Route path="/howdy" element={<WelcomePage />} />
                        <Route path="/auth/:type" element={<AuthPage />} />
                        <Route path="/dashboard" element={<TodayPage />} />
                        <Route path="/now" element={<TodayPage />} />
                        <Route path="/threads" element={<ThreadsPage />} />
                        <Route path="/minder" element={<ThreadsPage />} />
                        <Route path="/lists" element={<ListsPage />} />
                        <Route path="/you" element={<SettingsPage />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/privacy" element={<PrivacyPage />} />
                      </Routes>
                    </>
                  }
                />
              </Routes>
            </Router>
          </ThemeProvider>
        </IOSAuthInjectionProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
