import { useEffect, useRef } from "react";
import supabase from "./supabase";

// Make an API request to `/api/{path}`
export async function apiRequest(path: string, method = "GET", data?: any) {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session ? session.access_token : undefined;

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => {
      const json = response.json();
      return json;
    })
    .catch((e) => {
      return null;
    })
    .then((response) => {
      return response;
    });
}

// Make an API request to any external URL
export function apiRequestExternal(url: string, method: string, data: any) {
  return fetch(url, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

// Create an Error with custom message and code
export function CustomError(code: number, message: string) {
  const error = new Error(message);
  // error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}
