import { Button } from "@/ui/components/button";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardPreview,
  CardRow,
  CardTitle,
} from "@/ui/components/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/ui/components/dropdown-menu";
import { useAuth } from "@/util/auth";
import { type ListItem, createIncoming, deleteListItem } from "@/util/db";
import {
  Book,
  BookAudioIcon,
  File,
  FileText,
  Film,
  Globe,
  Landmark,
  Music,
  Podcast,
  ShoppingCart,
  Video,
  X,
} from "lucide-react";
import { MoreVertical } from "lucide-react";
import type React from "react";
import { useState } from "react";
const getTypeIcon = (type: string) => {
  switch (type) {
    case "book":
      return <Book className="text-blue-500" />;
    case "movie":
      return <Film className="text-red-500" />;
    case "article":
      return <FileText className="text-green-500" />;
    case "product":
      return <ShoppingCart className="text-yellow-500" />;
    case "album":
    case "song":
      return <Music className="text-purple-500" />;
    case "organization":
      return <Landmark className="text-teal-500" />;
    case "country":
    case "place":
      return <Globe className="text-blue-500" />;
    case "video":
      return <Video className="text-pink-500" />;
    case "podcast":
      return <Podcast className="text-orange-500" />;
    case "document":
      return <File className="text-indigo-500" />;
    default:
      return <FileText className="text-gray-500" />;
  }
};

export const ListItemView: React.FC<{ item: ListItem }> = ({ item }) => {
  const auth = useAuth();
  const [isBuying, setIsBuying] = useState(false);

  const parsedData =
    typeof item.data === "string" ? JSON.parse(item.data) : item.data;
  if (!parsedData) {
    return null;
  }
  const image = parsedData?.["twitter:image"] || parsedData?.image;
  const type = parsedData.type || "other";
  const icon = getTypeIcon(type);
  const showBuyButton = type === "book" || type === "product";

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent the link click
    deleteListItem(item.id);
  };

  const handleShare = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent the link click
    console.log("share");
  };

  const handleBuy = (item: ListItem) => {
    setIsBuying(true);
    createIncoming({
      command_text: `Buy ${item.name}`,
      owner: auth?.user?.id,
    });
  };

  return (
    <div className="relative">
      <a href={item.url} target="_blank" rel="noopener noreferrer">
        <CardRow className="flex-col border-t">
          {image && (
            <CardPreview>
              <div className=" overflow-hidden rounded-xl -m-2 -mb-4">
                <img
                  src={image}
                  alt={item.name}
                  className="h-full w-full object-cover object-top border"
                />
              </div>
            </CardPreview>
          )}

          <CardHeader className="flex flex-col">
            <div className="flex items-top justify-between">
              <CardTitle>{item.name}</CardTitle>
              <div className="-mr-3">
                <DropdownMenu>
                  <DropdownMenuTrigger className=" flex items-center z-10 text-gray-400 hover:text-gray-600 transition-colors">
                    {icon}
                    <MoreVertical className="h-5 w-5" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={handleShare}>
                      Share
                    </DropdownMenuItem>
                    {type === "book" && (
                      <DropdownMenuItem
                        onClick={(e) => {
                          handleBuy(item);
                        }}
                      >
                        Buy
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={handleDelete}>
                      Delete
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>{" "}
            <CardDescription className="max-h-20 overflow-hidden">
              {item.description}
            </CardDescription>
            {item.generated_summary_url && (
              <div className=" text-yellow-500 text-sm flex items-center gap-2">
                <BookAudioIcon className="h-4 w-4" /> has audio summary
              </div>
            )}
            {showBuyButton && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  if (!isBuying) {
                    handleBuy(item);
                  }
                }}
                className="ml-auto"
                variant="secondary"
                size="sm"
              >
                <ShoppingCart className="h-4 w-4 mr-2" />
                {isBuying ? "Buying..." : "Buy"}
              </Button>
            )}
          </CardHeader>
        </CardRow>
      </a>
    </div>
  );
};

export default ListItemView;
