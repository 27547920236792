import { requireAuth, useAuth } from "@/util/auth";
import ThreadsFullPage from "./ThreadsFullPage";
import ThreadsStudyPage from "./ThreadsStudyPage";

function ThreadsPage() {
  const auth = useAuth();

  const isFullUi = auth?.user?.setting_full_ui;

  return isFullUi ? <ThreadsFullPage /> : <ThreadsStudyPage />;
}

export default requireAuth(ThreadsPage);
