import { useAuth } from "@/util/auth";
import { useNavigate } from "@/util/router";
import { useEffect } from "react";

import "./QuietPage.css";

function QuietPage() {
  // Is there a way to just return dumb static content for this page instead
  // of shipping the whole react bundle (while still redirecting if authed)?
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth?.user) {
      navigate("/threads", { replace: true });
    }
  }, [auth, navigate]);

  return (
    <>
      <div className="circle" id="circle1" />
      <div className="circle" id="circle2" />
      <div className="circle" id="circle3" />
    </>
  );
}

export default QuietPage;
