import { ThreadCard } from "@/ui/components/ThreadCard";
import "@/ui/views/IncomingStudyItems.css";
import { CardContainer, CardPrefix } from "@/ui/components/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/ui/components/dropdown-menu";
import { Toolbar, ToolbarButton } from "@/ui/components/toolbar";
import { friendlyTimeSince } from "@/util/date";
import {
  archiveIncomingStudy,
  deleteIncomingStudy,
  updateIncomingStudy,
} from "@/util/db";
import type { IncomingStudy } from "@/util/db";
import { Archive, MoreVertical } from "lucide-react";
import { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

interface IncomingStudyItemsProps {
  incoming: IncomingStudy[];
}

export function IncomingStudyItems({ incoming }: IncomingStudyItemsProps) {
  const [showArchived, setShowArchived] = useState(false);

  if (!incoming || incoming.length === 0) return null;

  const filteredItems = incoming.filter(
    (item) => item.archived === showArchived,
  );

  const handleTitleChange = async (itemId: string, newTitle: string) => {
    await updateIncomingStudy(itemId, { text: newTitle });
  };

  return (
    <div>
      <Toolbar className="mr-2">
        <h1 className="text-4xl pt-3 font-bold">Minder</h1>
        <div className="flex-1" />

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <ToolbarButton variant="borderless" size="sm" className="gap-2">
              <MoreVertical className="h-4 w-4" />
            </ToolbarButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => setShowArchived(!showArchived)}>
              <Archive className="mr-2 h-4 w-4" />
              {showArchived ? "Show Active Items" : "Show Archived Items"}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </Toolbar>
      <TransitionGroup>
        {(() => {
          let lastTimestamp: Date | null = null;
          return filteredItems.map((item) => {
            const currentTimestamp = new Date(item.created_utc);
            const localTimestamp = new Date(
              currentTimestamp.getTime() -
                currentTimestamp.getTimezoneOffset() * 60000,
            );
            let timeText = null;

            if (
              !lastTimestamp ||
              localTimestamp.getTime() - lastTimestamp.getTime() > 1800000
            ) {
              timeText = friendlyTimeSince(localTimestamp);
            }
            lastTimestamp = localTimestamp;

            return (
              <CSSTransition
                key={item.id}
                timeout={218}
                classNames={{
                  exit: "container-exit",
                  exitActive: "container-exit-active",
                }}
              >
                <CardContainer>
                  {timeText && (
                    <CardPrefix className="text-sm font-mono-krypton text-muted-foreground align-right w-full justify-center">
                      {timeText}
                    </CardPrefix>
                  )}
                  <ThreadCard
                    id={item.id}
                    title={item.text}
                    audioUrl={item.audio_url}
                    onArchive={() =>
                      archiveIncomingStudy(item.id, !item.archived)
                    }
                    onDelete={() => deleteIncomingStudy(item.id)}
                    enableSwipe={true}
                    isArchived={item.archived}
                    metadata={item.metadata}
                    onTitleChange={handleTitleChange}
                  />
                </CardContainer>
              </CSSTransition>
            );
          });
        })()}
      </TransitionGroup>
    </div>
  );
}
